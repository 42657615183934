const Impressum = () => {
    return (<div>
        <div className="p-6">
            <strong>Impressum</strong>
            <p className="csc-firstHeader">Prof. Dr. Bela Gipp<br/>Papendiek 14<br/>D-37073
            Göttingen</p><p>Telefon: +49 (0)151 12 11 12 78<br/>E-Mail: <a
            href="mailto:gipp@uni-goettingen.de">gipp@uni-goettingen.de</a></p><p>&nbsp;</p><p><strong>Verantwortlich
            für den Inhalt</strong> (gem. § 55 Abs. 2 RStV):<br/>Lars Benedikt Kaesberg, Jan Philip Wahle, Terry Ruas, Norman
            Meuschke, Bela Gipp, Papendiek 14, D-37073 Göttingen</p><p>&nbsp;</p><strong
            id="disclaimer--rechtliche-hinweise">Disclaimer – rechtliche Hinweise</strong><p>§ 1 Warnhinweis zu
            Inhalten<br/>Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglicher
            Sorgfalt erstellt. Der Anbieter dieser Webseite übernimmt jedoch keine Gewähr für die Richtigkeit und
            Aktualität der bereitgestellten kostenlosen und frei zugänglichen journalistischen Ratgeber und Nachrichten.
            Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des
            Anbieters wieder. Allein durch den Aufruf der kostenlosen und frei zugänglichen Inhalte kommt keinerlei
            Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen
            des Anbieters.</p><p>§ 2 Externe Links<br/>Diese Website enthält Verknüpfungen zu Websites Dritter (“externe
            Links”). Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der
            erstmaligen Verknüpfung der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige
            Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei
            Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen
            von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte
            zu Eigen macht. Eine ständige Kontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise auf
            Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links
            unverzüglich gelöscht.</p><p>§ 3 Urheber- und Leistungsschutzrechte<br/>Die auf dieser Website
            veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen
            Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen
            Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere für Vervielfältigung,
            Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder
            anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet.
            Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet
            und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht
            kommerziellen Gebrauch ist erlaubt.</p><p>Die Darstellung dieser Website in fremden Frames ist nur mit
            schriftlicher Erlaubnis zulässig.</p><p>§ 4 Besondere Nutzungsbedingungen<br/>Soweit besondere Bedingungen
            für einzelne Nutzungen dieser Website von den vorgenannten Paragraphen abweichen, wird an entsprechender
            Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besonderen
            Nutzungsbedingungen.</p><p>Quelle: <a className="no-break-out"
                                                  href="https://www.juraforum.de/impressum-generator/">Impressum
            Generator von JuraForum.de</a></p><strong id="datenschutz">Datenschutz</strong><p>Wir informieren Sie nachfolgend
            gemäß den gesetzlichen Vorgaben des Datenschutzrechts (insb. gemäß BDSG n.F. und der europäischen
            Datenschutz-Grundverordnung ‚DS-GVO‘) über die Art, den Umfang und Zweck der Verarbeitung personenbezogener
            Daten durch unser Unternehmen.&nbsp;Diese Datenschutzerklärung gilt auch für unsere Websites und
            Sozial-Media-Profile. Bezüglich der Definition von Begriffen wie etwa „personenbezogene Daten“ oder
            „Verarbeitung“ verweisen wir auf Art. 4 DS-GVO.</p><p><strong>Name und Kontaktdaten des / der
            Verantwortlichen</strong><br/>Unser/e Verantwortliche/r (nachfolgend „Verantwortlicher“) i.S.d. Art. 4 Zif.
            7 DS-GVO ist:</p><p>Prof. Dr. Bela Gipp<br/>Papendiek 14<br/>D-37073 Göttingen<br/>E-Mail-Adresse: <a
            href="mailto:gipp@uni-goettingen.de">gipp@uni-goettingen.de</a></p><p><strong>Datenarten, Zwecke der
            Verarbeitung und Kategorien betroffener Personen</strong></p><p>Nachfolgend informieren wir Sie über Art,
            Umfang und Zweck der Erhebung, Verarbeitung und Nutzung personenbezogener Daten.</p><p><strong>1. Arten der
            Daten, die wir verarbeiten</strong><br/>Nutzungsdaten (Zugriffszeiten, besuchte Websites etc.),
            Bestandsdaten (Name, Adresse etc.), Kontaktdaten (Telefonnummer, E-Mail, Fax etc.), Inhaltsdaten
            (Texteingaben, Videos, Fotos etc.), Kommunikationsdaten (IP-Adresse etc.),</p><p><strong>2. Zwecke der
            Verarbeitung nach Art. 13 Abs. 1 c) DS-GVO</strong><br/>Website technisch und wirtschaftlich optimieren,
            Leichten Zugang zur Website ermöglichen, Optimierung und statistische Auswertung unserer Dienste,
            Nutzererfahrung verbessern, Website benutzerfreundlich gestalten, Erstellung von Statistiken,
            Kopierwahrscheinlichkeit von Texten ermitteln, Vermeidung von SPAM und Missbrauch, Abwicklung eines
            Bewerberverfahrens, Kundenservice und Kundenpflege, Kontaktanfragen abwickeln, Websites mit Funktionen und
            Inhalten bereitstellen, Maßnahmen der Sicherheit, Unterbrechungsfreier,sicherer Betrieb unserer Website,</p>
            <p><strong>3. Kategorien der betroffenen Personen nach Art. 13 Abs. 1 e) DS-GVO</strong><br/>Besucher/Nutzer
                der Website, Interessenten, Bewerber, Beschäftigte,</p><p>Die betroffenen Personen werden
                zusammenfassend als „Nutzer“ bezeichnet.</p><p><strong>Rechtsgrundlagen der Verarbeitung
                personenbezogener Daten</strong></p><p>Nachfolgend Informieren wir Sie über die Rechtsgrundlagen der
                Verarbeitung personenbezogener Daten:</p>
            <ol>
                <li>Wenn wir Ihre Einwilligung für die Verarbeitung personenbezogenen Daten eingeholt haben, ist Art. 6
                    Abs. 1 S. 1 lit. a) DS-GVO Rechtsgrundlage.
                </li>
                <li>Ist die Verarbeitung zur Erfüllung eines Vertrags oder zur Durchführung vorvertraglicher Maßnahmen
                    erforderlich, die auf Ihre Anfrage hin erfolgen, so ist Art. 6 Abs. 1 S. 1 lit. b) DS-GVO
                    Rechtsgrundlage.
                </li>
                <li>Ist die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der wir unterliegen
                    (z.B. gesetzliche Aufbewahrungspflichten), so ist Art. 6 Abs. 1 S. 1 lit. c) DS-GVO Rechtsgrundlage.
                </li>
                <li>Ist die Verarbeitung erforderlich, um lebenswichtige Interessen der betroffenen Person oder einer
                    anderen natürlichen Person zu schützen, so ist Art. 6 Abs. 1 S. 1 lit. d) DS-GVO Rechtsgrundlage.
                </li>
                <li>Ist die Verarbeitung zur Wahrung unserer oder der berechtigten Interessen eines Dritten erforderlich
                    und überwiegen diesbezüglich Ihre Interessen oder Grundrechte und Grundfreiheiten nicht, so ist Art.
                    6 Abs. 1 S. 1 lit. f) DS-GVO Rechtsgrundlage.
                </li>
            </ol>
            <p><strong>Weitergabe personenbezogener Daten an Dritte und Auftragsverarbeiter</strong></p><p>Ohne Ihre
                Einwilligung geben wir grundsätzlich keine Daten an Dritte weiter. Sollte dies doch der Fall sein, dann
                erfolgt die Weitergabe auf der Grundlage der zuvor genannten Rechtsgrundlagen z.B. bei der Weitergabe
                von Daten an Online-Paymentanbieter zur Vertragserfüllung oder aufgrund gerichtlicher Anordnung oder
                wegen einer gesetzlichen Verpflichtung zur Herausgabe der Daten zum Zwecke der Strafverfolgung, zur
                Gefahrenabwehr oder zur Durchsetzung der Rechte am geistigen Eigentum.<br/>Wir setzen zudem
                Auftragsverarbeiter (externe Dienstleister z.B. zum Webhosting unserer Websites und Datenbanken) zur
                Verarbeitung Ihrer Daten ein. Wenn im Rahmen einer Vereinbarung zur Auftragsverarbeitung an die
                Auftragsverarbeiter Daten weitergegeben werden, erfolgt dies immer nach Art. 28 DS-GVO. Wir wählen dabei
                unsere Auftragsverarbeiter sorgfältig aus, kontrollieren diese regelmäßig und haben uns ein
                Weisungsrecht hinsichtlich der Daten einräumen lassen. Zudem müssen die Auftragsverarbeiter geeignete
                technische und organisatorische Maßnahmen getroffen haben und die Datenschutzvorschriften gem. BDSG n.F.
                und DS-GVO einhalten</p><p><strong>Datenübermittlung in Drittstaaten</strong></p><p>Durch die
                Verabschiedung der europäischen Datenschutz-Grundverordnung (DS-GVO) wurde eine einheitliche Grundlage
                für den Datenschutz in Europa geschaffen. Ihre Daten werden daher vorwiegend durch Unternehmen
                verarbeitet, für die DS-GVO Anwendung findet. Sollte doch die Verarbeitung durch Dienste Dritter
                außerhalb der Europäischen Union oder des Europäischen Wirtschaftsraums stattfinden, so müssen diese die
                besonderen Voraussetzungen der Art. 44 ff. DS-GVO erfüllen. Das bedeutet, die Verarbeitung erfolgt
                aufgrund besonderer Garantien, wie etwa die von der EU-Kommission offiziell anerkannte Feststellung
                eines der EU entsprechenden Datenschutzniveaus oder der Beachtung offiziell anerkannter spezieller
                vertraglicher Verpflichtungen, der so genannten „Standardvertragsklauseln“. Bei US-Unternehmen erfüllt
                die Unterwerfung unter das sog. „Privacy-Shield“, dem Datenschutzabkommen zwischen der EU und den USA,
                diese Voraussetzungen.</p><p><strong>Löschung von Daten und Speicherdauer</strong></p><p>Sofern nicht in
                dieser Datenschutzerklärung ausdrücklich angegeben, werden Ihre personenbezogen Daten gelöscht oder
                gesperrt, sobald der Zweck für die Speicherung entfällt, es sei denn deren weitere Aufbewahrung ist zu
                Beweiszwecken erforderlich oder dem stehen gesetzliche Aufbewahrungspflichten entgegenstehen. Darunter
                fallen etwa handelsrechtliche Aufbewahrungspflichten von Geschäftsbriefen nach § 257 Abs. 1 HGB (6
                Jahre) sowie steuerrechtliche Aufbewahrungspflichten nach § 147 Abs. 1 AO von Belegen (10 Jahre). Wenn
                die vorgeschriebene Aufbewahrungsfrist abläuft, erfolgt eine Sperrung oder Löschung Ihrer Daten, es sei
                denn die Speicherung ist weiterhin für einen Vertragsabschluss oder zur Vertragserfüllung
                erforderlich.</p><p><strong>Bestehen einer automatisierten Entscheidungsfindung</strong></p><p>Wir
                setzen keine automatische Entscheidungsfindung oder ein Profiling ein.</p><p><strong>Bereitstellung
                unserer Website und Erstellung von Logfiles</strong></p>
            <ol>
                <li>Wenn Sie unsere Webseite lediglich informatorisch nutzen (also keine Registrierung und auch keine
                    anderweitige Übermittlung von Informationen), erheben wir nur die personenbezogenen Daten, die Ihr
                    Browser an unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten, erheben wir die
                    folgenden Daten:<br/>* IP-Adresse;<br/>* Internet-Service-Provider des Nutzers;<br/>* Datum und
                    Uhrzeit des Abrufs;<br/>* Browsertyp;<br/>* Sprache und Browser-Version;<br/>* Inhalt des
                    Abrufs;<br/>* Zeitzone;<br/>* Zugriffsstatus/HTTP-Statuscode;<br/>* Datenmenge;<br/>* Websites, von
                    denen die Anforderung kommt;<br/>* Betriebssystem.<br/>Eine Speicherung dieser Daten zusammen mit
                    anderen personenbezogenen Daten von Ihnen findet nicht statt.
                </li>
                <li>Diese Daten dienen dem Zweck der nutzerfreundlichen, funktionsfähigen und sicheren Auslieferung
                    unserer Website an Sie mit Funktionen und Inhalten sowie deren Optimierung und statistischen
                    Auswertung.
                </li>
                <li>Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes berechtigtes Interesse an der
                    Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.
                </li>
                <li>Wir speichern aus Sicherheitsgründen diese Daten in Server-Logfiles für die Speicherdauer von Tagen.
                    Nach Ablauf dieser Frist werden diese automatisch gelöscht, es sei denn wir benötigen deren
                    Aufbewahrung zu Beweiszwecken bei Angriffen auf die Serverinfrastruktur oder anderen
                    Rechtsverletzungen.
                </li>
            </ol>
            <p><strong>Cookies</strong></p>
            <ol>
                <li>Wir verwenden sog. Cookies bei Ihrem Besuch unserer Website. Cookies sind kleine Textdateien, die
                    Ihr Internet-Browser auf Ihrem Rechner ablegt und speichert. Wenn Sie unsere Website erneut
                    aufrufen, geben diese Cookies Informationen ab, um Sie automatisch wiederzuerkennen. Die so
                    erlangten Informationen dienen dem Zweck, unsere Webangebote technisch und wirtschaftlich zu
                    optimieren und Ihnen einen leichteren und sicheren Zugang auf unsere Website zu ermöglichen. Wir
                    informieren Sie dazu beim Aufruf unserer Website mittels eines Hinweises auf unsere
                    Datenschutzerklärung über die Verwendung von Cookies zu den zuvor genannten Zwecken und wie Sie
                    dieser widersprechen bzw. deren Speicherung verhindern können („Opt-out“). Unsere Website nutzt
                    Session-Cookies, persistente Cookies und Cookies von Drittanbietern:<strong>•
                        Session-Cookies:</strong> Wir verwenden sog. Cookies zum Wiedererkennen mehrfacher Nutzung eines
                    Angebots durch denselben Nutzer (z.B. wenn Sie sich eingeloggt haben zur Feststellung Ihres
                    Login-Status). Wenn Sie unsere Seite erneut aufrufen, geben diese Cookies Informationen ab, um Sie
                    automatisch wiederzuerkennen. Die so erlangten Informationen dienen dazu, unsere Angebote zu
                    optimieren und Ihnen einen leichteren Zugang auf unsere Seite zu ermöglichen. Wenn Sie den Browser
                    schließen oder Sie sich ausloggen, werden die Session-Cookies gelöscht.<strong>• Persistente
                        Cookies:</strong> Diese werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je
                    nach Cookie unterscheiden kann. In den Sicherheitseinstellungen Ihres Browsers können Sie die
                    Cookies jederzeit löschen.<strong>• Cookies von Drittanbietern
                        (Third-Party-Cookies):</strong> Entsprechend Ihren Wünschen können Sie können Ihre
                    Browser-Einstellung konfigurieren und z. B. Die Annahme von Third-Party-Cookies oder allen Cookies
                    ablehnen. Wir weisen Sie jedoch an dieser Stelle darauf hin, dass Sie dann eventuell nicht alle
                    Funktionen dieser Website nutzen können. Lesen Sie Näheres zu diesen Cookies bei den jeweiligen
                    Datenschutzerklärungen zu den Drittanbietern.
                </li>
                <li>Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 S. lit. b) DS-GVO, wenn die Cookies zur
                    Vertragsanbahnung z.B. bei Bestellungen gesetzt werden und ansonsten haben wir ein berechtigtes
                    Interesse an der effektiven Funktionalität der Website, so dass in dem Falle Art. 6 Abs. 1 S. 1 lit.
                    f) DS-GVO Rechtsgrundlage ist.
                </li>
                <li><strong>Widerspruch und „Opt-Out“:</strong> Das Speichern von Cookies auf Ihrer Festplatte können
                    Sie allgemein verhindern, indem Sie in Ihren Browser-Einstellungen „keine Cookies akzeptieren“
                    wählen. Dies kann aber eine Funktionseinschränkung unserer Angebote zur Folge haben. Sie können dem
                    Einsatz von Cookies von Drittanbietern zu Werbezwecken über ein sog. „Opt-out“ über diese
                    amerikanische Website&nbsp;(<a className="no-break-out" href="https://optout.aboutads.info"
                                                   target="_blank"
                                                   rel="nofollow noopener noreferrer">https://optout.aboutads.info</a>)&nbsp;oder
                    diese europäische Website&nbsp;(<a className="no-break-out"
                                                       href="http://www.youronlinechoices.com/de/praferenzmanagement/"
                                                       target="_blank"
                                                       rel="nofollow noopener noreferrer">http://www.youronlinechoices.com/de/praferenzmanagement/</a>)
                    widersprechen.
                </li>
            </ol>
            <p><strong>Kontaktaufnahme per Kontaktformular / E-Mail / Fax / Post</strong></p>
            <ol>
                <li>Bei der Kontaktaufnahme mit uns per Kontaktformular, Fax, Post oder E-Mail werden Ihre Angaben zum
                    Zwecke der Abwicklung der Kontaktanfrage verarbeitet.
                </li>
                <li>Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung von Ihnen
                    Art.&nbsp;6 Abs.&nbsp;1 S. 1 lit.&nbsp;a) DS-GVO. Rechtsgrundlage für die Verarbeitung der Daten,
                    die im Zuge einer Kontaktanfrage oder E-Mail, eines Briefes oder Faxes übermittelt werden, ist
                    Art.&nbsp;6 Abs.&nbsp;1 S. 1 lit.&nbsp;f) DS-GVO. Der Verantwortliche hat ein berechtigtes Interesse
                    an der Verarbeitung und Speicherung der Daten, um Anfragen der Nutzer beantworten zu können, zur
                    Beweissicherung aus Haftungsgründen und um ggf. seiner gesetzlichen Aufbewahrungspflichten bei
                    Geschäftsbriefen nachkommen zu können. Zielt der Kontakt auf den Abschluss eines Vertrages ab, so
                    ist zusätzliche Rechtsgrundlage für die Verarbeitung Art.&nbsp;6 Abs.&nbsp;1 S. 1 lit.&nbsp;b)
                    DS-GVO.
                </li>
                <li>Wir können Ihre Angaben und Kontaktanfrage in unserem Customer-Relationship-Management System (“CRM
                    System”) oder einem vergleichbaren System speichern.
                </li>
                <li>Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr
                    erforderlich sind. Für die personenbezogenen Daten aus der Eingabemaske des Kontaktformulars und
                    diejenigen, die per E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige Konversation
                    mit Ihnen beendet ist. Beendet ist die Konversation dann, wenn sich aus den Umständen entnehmen
                    lässt, dass der betroffene Sachverhalt abschließend geklärt ist. Anfragen von Nutzern, die über
                    einen Account bzw. Vertrag mit uns verfügen, speichern wir bis zum Ablauf von zwei Jahren nach
                    Vertragsbeendigung. Im Fall von gesetzlichen Archivierungspflichten erfolgt die Löschung nach deren
                    Ablauf: Ende handelsrechtlicher (6 Jahre) und steuerrechtlicher (10 Jahre) Aufbewahrungspflicht.
                </li>
                <li>Sie haben jederzeit die Möglichkeit, die Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a) DS-GVO zur
                    Verarbeitung der personenbezogenen Daten zu widerrufen. Nehmen Sie per E-Mail Kontakt mit uns auf,
                    so können Sie der Speicherung der personenbezogenen Daten jederzeit widersprechen.
                </li>
            </ol>
            <p><strong>Google Analytics</strong></p>
            <ol>
                <li>
                    <ol>
                        <li>Wir haben das Webseitenanalyse-Tool „Google Analytics“ (Google Ireland Limited, Registernr.:
                            368047, Gordon House, Barrow Street, Dublin 4, Irland) auf unserer Website integriert.
                        </li>
                        <li>Beim Besuch unserer Website setzt Google einen Cookie auf Ihren Computer, um die Benutzung
                            unserer Website durch Sie analysieren zu können. Die gewonnenen Daten werden in die USA
                            übertragen und dort gespeichert. Falls personenbezogen Daten in die USA übertragen werden
                            sollten, bietet die Zertifizierung Googles gemäß Privacy-Shield-Abkommen (<a
                                className="no-break-out" href="https://www.privacyshield.gov/EU-US-Framework"
                                target="_blank"
                                rel="nofollow noopener noreferrer">https://www.privacyshield.gov/EU-US-Framework</a>)
                            die Garantie dafür, dass das europäische Datenschutzrecht eingehalten wird.
                        </li>
                        <li>Wir haben die IP-Anonymisierung „anonymizeIP“ aktiviert, wodurch die IP-Adressen nur gekürzt
                            weiterverarbeitet werden. Auf dieser Webseite wird Ihre IP-Adresse von Google daher
                            innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
                            Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird
                            die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im
                            Auftrag des Betreibers dieser Webseite wird Google diese Informationen benutzen, um Ihre
                            Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen
                            und um weitere, mit der Websitenutzung und der Internetnutzung verbundene, Dienstleistungen
                            gegenüber dem Verantwortlichen zu erbringen. Wir haben darüber hinaus die
                            geräteübergreifende Analyse von Website-Besuchern aktiviert, die über eine sog. User-ID
                            durchgeführt wird. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte
                            IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Die Nutzung von Google
                            Analytics dient dem Zweck der Analyse, Optimierung und Verbesserung unserer Website.
                        </li>
                        <li>Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes berechtigtes
                            Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.
                        </li>
                        <li>Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs
                            verknüpften Daten werden nach 36 Monaten automatisch gelöscht. Die Löschung von Daten, deren
                            Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im Monat.
                        </li>
                        <li>Weitere Informationen zu Datennutzung bei Google Analytics finden Sie hier: <a
                            className="no-break-out" href="https://www.google.com/analytics/terms/de.html"
                            target="_blank"
                            rel="nofollow noopener noreferrer">https://www.google.com/analytics/terms/de.html</a> (Nutzungsbedingungen
                            von Analytics), <a className="no-break-out"
                                               href="https://support.google.com/analytics/answer/6004245?hl=de"
                                               target="_blank"
                                               rel="nofollow noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=de</a> (Hinweise
                            zum Datenschutz bei Analytics) und Googles Datenschutzerklärung <a className="no-break-out"
                                                                                               href="https://policies.google.com/privacy"
                                                                                               target="_blank"
                                                                                               rel="nofollow noopener noreferrer">https://policies.google.com/privacy</a>.
                        </li>
                        <li>Widerspruch und „Opt-Out“: Das Speichern von Cookies auf Ihrer Festplatte können Sie
                            allgemein verhindern, indem Sie in Ihren Browser-Einstellungen „keine Cookies akzeptieren“
                            wählen. Dies kann aber eine Funktionseinschränkung unserer Angebote zur Folge haben. Sie
                            können darüber hinaus die Erfassung der, durch das Cookie erzeugten und auf Ihre Nutzung der
                            Website bezogenen, Daten an Google sowie die Verarbeitung dieser Daten durch Google
                            verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen
                            und installieren: <a className="no-break-out"
                                                 href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
                                                 rel="nofollow noopener noreferrer">http://tools.google.com/dlpage/gaoptout?hl=de</a>
                        </li>
                    </ol>
                </li>
            </ol>
            <ol>
                <li>Als Alternative zum obigen Browser-Plugin können Sie die Erfassung durch Google Analytics
                    unterbinden, indem Sie <a className="gaoo-opt-out google-analytics-opt-out">hier</a> klicken. Durch
                    den Klick wird ein „Opt-out“-Cookie gesetzt, das die Erfassung Ihrer Daten beim Besuch dieser
                    Webseite zukünftig verhindert. Dieses Cookie gilt nur für unsere Webseite und Ihren aktuellen
                    Browser und hat nur solange Bestand bis Sie Ihre Cookies löschen. In dem Falle müssten Sie das
                    Cookie erneut setzen.
                </li>
                <li>Die geräteübergreifende Nutzeranalyse können Sie in Ihrem Google-Account unter „Meine
                    Daten &gt; persönliche Daten“ deaktivieren.
                </li>
            </ol>
            <p><strong>YouTube-Videos</strong></p>
            <ol>
                <li>Wir haben auf unserer Website YouTube-Videos von youtube.com mittels der embedded-Funktion
                    eingebunden, so dass diese auf unserer Website direkt aufrufbar sind. YouTube gehört zur Google
                    Ireland Limited, Registernr.: 368047, Gordon House, Barrow Street, Dublin 4, Irland. Wir haben die
                    Videos im sog. „erweiterten Datenschutz-Modus“ eingebunden, ohne dass mit Cookies das
                    Nutzungsverhalten erfasst wird, um die Videowiedergabe zu personalisieren. Stattdessen basieren die
                    Videoempfehlungen auf dem aktuell abgespielten Video. Videos, die im erweiterten Datenschutzmodus in
                    einem eingebetteten Player wiedergegeben werden, wirken sich nicht darauf aus, welche Videos Ihnen
                    auf YouTube empfohlen werden.Beim Start eines Videos (Klick aufs Video) erhält YouTube die
                    Information, dass Sie die entsprechende Unterseite unserer Website aufgerufen haben. Die gewonnenen
                    Daten werden in die USA übertragen und dort gespeichert. Dies erfolgt auch ohne Nutzerkonto bei
                    Google. Sollten Sie in Ihren Google-Account eingeloggt sein, kann Google die obigen Daten Ihrem
                    Account zuordnen. Wenn Sie dies nicht wünschen, müssen Sie sich in Ihrem Google-Account ausloggen.
                    Google erstellt aus solchen Daten Nutzerprofile und nutzt diese Daten zum Zwecke der Werbung,
                    Marktforschung oder Optimierung seiner Websites.
                </li>
                <li>Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes berechtigtes Interesse an der
                    Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.
                </li>
                <li>Sie haben gegenüber Google ein Widerspruchsrecht gegen die Bildung von Nutzerprofilen. Bitte richten
                    Sie sich deswegen direkt an Google über die unten genannte Datenschutzerklärung. Ein
                    Opt-Out-Widerspruch hinsichtlich der Werbe-Cookies können Sie hier in Ihrem Google-Account
                    vornehmen:<br/><a className="no-break-out" href="https://adssettings.google.com/authenticated"
                                      target="_blank"
                                      rel="nofollow noopener noreferrer">https://adssettings.google.com/authenticated</a>.
                </li>
                <li>In den Nutzungsbedingungen von YouTube unter <a className="no-break-out"
                                                                    href="https://www.youtube.com/t/terms"
                                                                    target="_blank"
                                                                    rel="nofollow noopener noreferrer">https://www.youtube.com/t/terms</a> und
                    in der Datenschutzerklärung für Werbung von Google unter <a className="no-break-out"
                                                                                href="https://policies.google.com/technologies/ads"
                                                                                target="_blank"
                                                                                rel="nofollow noopener noreferrer">https://policies.google.com/technologies/ads</a> finden
                    Sie weitere Informationen zur Verwendung von Google Cookies und deren Werbetechnologien,
                    Speicherdauer, Anonymisierung, Standortdaten, Funktionsweise und Ihre Rechte. Allgemeine
                    Datenschutzerklärung von Google: <a className="no-break-out"
                                                        href="https://policies.google.com/privacy" target="_blank"
                                                        rel="nofollow noopener noreferrer">https://policies.google.com/privacy</a>.
                </li>
                <li>Google ist nach dem EU-US Privacy Shield zertifiziert (<a className="no-break-out"
                                                                              href="https://www.privacyshield.gov/EU-US-Framework"
                                                                              target="_blank"
                                                                              rel="nofollow noopener noreferrer">https://www.privacyshield.gov/EU-US-Framework</a>)
                    und daher verpflichtet europäisches Datenschutzrecht einzuhalten.
                </li>
            </ol>
            <p><strong>Google ReCAPTCHA</strong></p>
            <ol>
                <li>Wir haben auf unserer Webseite die Anti-Spam-Funktion „reCAPTCHA“ von „Google“ (Google Ireland
                    Limited, Registernr.: 368047, Gordon House, Barrow Street, Dublin 4, Irland) integriert. Durch den
                    Einsatz von „reCAPTCHA“ in unseren Formularen können wir feststellen, ob die Eingabe durch eine
                    Maschine (Robot) oder einen Menschen erfolgte. Bei der Nutzung des Dienstes können Ihre IP-Adresse
                    und ggf. weitere dafür benötigte Daten an Google-Server in den USA übertragen werden.
                </li>
                <li>Zweck der Verarbeitung dieser Daten ist die Vermeidung von Spam und Missbrauch sowie unser
                    wirtschaftliches Interesse an der Optimierung unserer Website.
                </li>
                <li>Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes berechtigtes Interesse an der
                    Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.
                </li>
                <li>Google ist unter dem Privacy-Shield-Abkommen zertifiziert (<a className="no-break-out"
                                                                                  href="https://www.privacyshield.gov/EU-US-Framework"
                                                                                  target="_blank"
                                                                                  rel="nofollow noopener noreferrer">https://www.privacyshield.gov/EU-US-Framework</a>).
                    So wird sichergestellt, dass das europäische Datenschutzrecht eingehalten wird.
                </li>
                <li>Weitere Informationen finden Sie zu Google ReCAPTCHA unter <a className="no-break-out"
                                                                                  href="https://www.google.com/recaptcha/"
                                                                                  target="_blank"
                                                                                  rel="nofollow noopener noreferrer">https://www.google.com/recaptcha/</a> sowie
                    in der Datenschutzerklärung von Google unter: <a className="no-break-out"
                                                                     href="https://policies.google.com/privacy"
                                                                     target="_blank"
                                                                     rel="nofollow noopener noreferrer">https://policies.google.com/privacy</a>.
                </li>
            </ol>
            <p><strong>Google Maps</strong></p>
            <ol>
                <li>Wir haben auf unserer Website Karten von „Google Maps“ (Google Ireland Limited, Registernr.: 368047,
                    Gordon House, Barrow Street, Dublin 4, Irland) integriert. Damit können wirden Standort von Adressen
                    und eine Anfahrtsbeschreibung direkt auf unserer Website in interaktiven Karten anzeigen und Ihnen
                    die Nutzung dieses Tools ermöglichen.
                </li>
                <li>Bei dem Abruf unserer Website, wo Google Maps integriert ist, wird eine Verbindung zu den Servern
                    von Google in den USA aufgebaut. Hierbei können Ihre IP und Standort an Google übertragen werden.
                    Zudem erhält Google die Information, dass Sie die entsprechende Seite aufgerufen haben. Dies erfolgt
                    auch ohne Nutzerkonto bei Google. Sollten Sie in Ihren Google-Account eingeloggt sein, kann Google
                    die obigen Daten Ihrem Account zuordnen. Wenn Sie dies nicht wünschen, müssen Sie sich bei Ihrem
                    Google-Account ausloggen. Google erstellt aus solchen Daten Nutzerprofile und nutzt diese Daten zum
                    Zwecke der Werbung, Marktforschung oder Optimierung seiner Websites.
                </li>
                <li>Rechtsgrundlage hierfür ist unser in den obigen Zwecken auch liegendes berechtigtes Interesse an der
                    Datenverarbeitung nach Art. 6 Abs. 1 S.1 lit. f) DS-GVO.
                </li>
                <li>Sie haben gegenüber Google ein Widerspruchsrecht gegen die Bildung von Nutzerprofilen. Bitte richten
                    Sie sich deswegen direkt an Google über die unten genannte Datenschutzerklärung. Ein
                    Opt-Out-Widerspruch hinsichtlich der Werbe-Cookies können Sie hier in Ihrem Google-Account
                    vornehmen:<br/><a className="no-break-out" href="https://adssettings.google.com/authenticated"
                                      target="_blank"
                                      rel="nofollow noopener noreferrer">https://adssettings.google.com/authenticated</a>.
                </li>
                <li>In den Nutzungsbedingungen von Google Maps unter <a className="no-break-out"
                                                                        href="https://www.google.com/intl/de_de/help/terms_maps.html"
                                                                        target="_blank"
                                                                        rel="nofollow noopener noreferrer">https://www.google.com/intl/de_de/help/terms_maps.html</a> und
                    in der Datenschutzerklärung für Werbung von Google unter <a className="no-break-out"
                                                                                href="https://policies.google.com/technologies/ads"
                                                                                target="_blank"
                                                                                rel="nofollow noopener noreferrer">https://policies.google.com/technologies/ads</a> finden
                    Sie weitere Informationen zur Verwendung von Google Cookies und deren Werbetechnologien,
                    Speicherdauer, Anonymisierung, Standortdaten, Funktionsweise und Ihre Rechte. Allgemeine
                    Datenschutzerklärung von Google: <a className="no-break-out"
                                                        href="https://policies.google.com/privacy" target="_blank"
                                                        rel="nofollow noopener noreferrer">https://policies.google.com/privacy</a>.
                </li>
                <li>Google ist nach dem EU-US Privacy Shield zertifiziert (<a className="no-break-out"
                                                                              href="https://www.privacyshield.gov/EU-US-Framework"
                                                                              target="_blank"
                                                                              rel="nofollow noopener noreferrer">https://www.privacyshield.gov/EU-US-Framework</a>)
                    und daher verpflichtet europäisches Datenschutzrecht einzuhalten.
                </li>
            </ol>
            <p><strong>Präsenz in sozialen Medien</strong></p>
            <ol>
                <li>Wir unterhalten in sozialen Medien Profile bzw. Fanpages, um mit den dort angeschlossenen und
                    registrierten Nutzern zu kommunizieren und um über unsere Produkte, Angebote und Dienstleistungen zu
                    informieren. Die US-Anbieter sind nach dem sog. Privacy-Shield zertifiziert und damit verpflichtet
                    europäischen Datenschutz einzuhalten. Bei der Nutzung und dem Aufruf unseres Profils im jeweiligen
                    Netzwerk durch Sie gelten die jeweiligen Datenschutzhinweise und Nutzungsbedingungen des jeweiligen
                    Netzwerks.
                </li>
                <li>Wir verarbeiten Ihre Daten, die Sie uns über diese Netzwerke senden, um mit Ihnen zu kommunizieren
                    und um Ihre dortigen Nachrichten zu beantworten.
                </li>
                <li>Die Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten ist unser berechtigtes
                    Interesse an der Kommunikation mit den Nutzern und unsere Außendarstellung zwecks Werbung gemäß Art.
                    6 Abs. 1 S. 1 lit. f) DS-GVO. Soweit Sie dem Verantwortlichen des sozialen Netzwerks eine
                    Einwilligung in die Verarbeitung Ihrer personenbezogenen Daten erteilt haben, ist Rechtsgrundlage
                    Art. 6 Abs. 1 S. 1 lit. a) und Art. 7 DS-GVO.
                </li>
                <li>Die Datenschutzhinweise, Auskunftsmöglichkeiten und Widerspruchmöglichkeiten (Opt-Out) der
                    jeweiligen Netzwerke finden Sie hier:• <strong>Twitter</strong> (Twitter Inc., 1355 Market Street,
                    Suite 900, San Francisco, CA 94103, USA) – Datenschutzerklärung: <a className="no-break-out"
                                                                                        href="https://twitter.com/de/privacy"
                                                                                        target="_blank"
                                                                                        rel="nofollow noopener noreferrer">https://twitter.com/de/privacy</a>,
                    Opt-Out: <a className="no-break-out" href="https://twitter.com/personalization" target="_blank"
                                rel="nofollow noopener noreferrer">https://twitter.com/personalization</a>, Privacy
                    Shield: <a className="no-break-out"
                               href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active"
                               target="_blank"
                               rel="nofollow noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active</a>.
                </li>
            </ol>
            <p><strong>Social-Media-Plug-ins</strong></p>
            <ol>
                <li>Wir setzen auf unserer Webseite Social-Media-Plug-ins von sozialen Netzwerken ein. Hierbei handelt
                    es sich um sog. Thirdparty Cookies. Bei einigen Anbietern wie Facebook und XING wird nach deren
                    Angaben Ihre IP nach der Erhebung sofort anonymisiert.
                </li>
                <li>Die über den Nutzer erhobenen Daten speichert der Plug-in-Anbieter als Nutzungsprofile. Diese werden
                    für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website
                    verwendet. Eine solche Auswertung erfolgt insbesondere (auch für nicht eingeloggte Nutzer) zur
                    Darstellung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über die
                    Aktivitäten des Nutzers auf unserer Website zu informieren. Dem Nutzer steht ein Widerspruchsrecht
                    gegen die Bildung dieser Nutzerprofile zu, wobei man sich zur Ausübung dieses Rechts an den
                    jeweiligen Plug-in-Anbieter wenden muss.
                </li>
                <li>Rechtsgrundlage für die Nutzung der Plug-ins ist unser berechtigtes Interesse an der Verbesserung
                    und Optimierung unserer Website durch die Steigerung unserer Bekanntheit mittels sozialer Netzwerke
                    sowie die Möglichkeit der Interaktion mit Ihnen und der Nutzer untereinander über soziale Netzwerke
                    gemäß Art. 6 Abs. 1 S.1 lit. f) DS-GVO.
                </li>
                <li>Auf die erhobenen Daten und Datenverarbeitungsvorgänge haben wir keinen Einfluss. Auch haben wir
                    keine Kenntnis vom Umfang der Datenerhebung, vom Zweck der Verarbeitung und von den Speicherfristen.
                    Auch zur Löschung der erhobenen Daten durch den Plug-in-Anbieter liegen uns keine Informationen vor.
                </li>
                <li>Wir verweisen hinsichtlich des Zwecks und Umfangs der Datenerhebung und Verarbeitung auf die
                    jeweiligen Datenschutzerklärungen der sozialen Netzwerke. Zudem finden Sie dort auch Hinweise zu
                    Ihren Rechten und Einstellungsmöglichkeiten zum Schutz Ihrer personenbezogenen Daten.
                </li>
            </ol>
            <p><strong>Twitter</strong></p>
            <ol>
                <li>Wir haben auf unserer Website Plug-Ins des sozialen Netzwerks Twitter.com (Twitter Inc., 1355 Market
                    St., Suite 900, San Francisco, California 94103, USA) im Rahmen der sog. „Zwei-Klick-Lösung“ von
                    Shariff integriert. Diese Plug-Ins erkennen Sie an dem Twitter-Logo mit weißem Vogel auf blauem
                    Hintergrund. Eine Übersicht über Twitter-Buttons bzw. Tweets finden Sie unter: <a
                        className="no-break-out"
                        href="https://developer.twitter.com/en/docs/twitter-for-websites/overview" target="_blank"
                        rel="nofollow noopener noreferrer">https://developer.twitter.com/en/docs/twitter-for-websites/overview</a>.
                </li>
                <li>Wenn Sie in Ihren Twitter-Account eingeloggt sind, während Sie die Twitter-Plug-ins willentlich
                    aktivieren, kann Twitter den Anruf unserer Website Ihrem Twitter-Profil zuordnen. Welche Daten an
                    Twitter übermittelt werden, ist uns nicht bekannt.
                </li>
                <li>Wenn Sie die Datenübermittlung bei Aktivierung des Plug-ins an Twitter ausschließen möchten, dann
                    loggen Sie sich vor dem Besuch unserer Website bei Twitter aus und löschen Ihre Cookies.
                </li>
                <li>Zweck und Umfang der Datenerhebung sowie ihre weitere Verarbeitung und Nutzung der Daten durch
                    Twitter sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer
                    Privatsphäre, können Sie den Datenschutzhinweisen von Twitter entnehmen:&nbsp;<a
                        className="no-break-out" href="https://twitter.com/de/privacy" target="_blank"
                        rel="nofollow noopener noreferrer">https://twitter.com/de/privacy</a>. Widerspruch (Opt-Out): <a
                        className="no-break-out" href="https://twitter.com/personalization" target="_blank"
                        rel="nofollow noopener noreferrer">https://twitter.com/personalization</a>.
                </li>
                <li>Twitter hat sich dem Privacy Shield unterworfen und stellt damit sicher, dass europäisches
                    Datenschutzrecht eingehalten wird: <a className="no-break-out"
                                                          href="https://www.privacyshield.gov/EU-US-Framework"
                                                          target="_blank"
                                                          rel="nofollow noopener noreferrer">https://www.privacyshield.gov/EU-US-Framework</a>.
                </li>
            </ol>
            <p><strong>Datenschutz bei Bewerbungen und im Bewerberverfahren</strong></p>
            <ol>
                <li>Bewerbungen, die auf elektronischem Wege oder per Post an den Verantwortlichen gesendet werden,
                    werden zum Zwecke der Abwicklung des Bewerberverfahrens elektronisch oder manuell verarbeitet.
                </li>
                <li>Wir weisen ausdrücklich darauf hin, dass Bewerbungsunterlagen mit „besonderen Kategorien
                    personenbezogener Daten“ nach Art. 9 DS-GVO (z.B. ein Foto, welches Rückschlüsse auf Ihre ethnische
                    Herkunft, Religion oder Ihren Familienstand gibt), mit Ausnahme einer eventuellen Schwerbehinderung,
                    welche Sie aus freier Entscheidung offenlegen möchten, unerwünscht sind. Sie sollen Ihre Bewerbung
                    ohne diese Daten einreichen. Dies hat keine Auswirkungen auf Ihre Bewerberchancen.
                </li>
                <li>Rechtsgrundlagen für die Verarbeitung sind Art. 6 Abs. 1 S.1 lit. b) DS-GVO sowie § 26 BDSG n.F.
                </li>
                <li>Wird nach Abschluss des Bewerberverfahrens, ein Arbeitsverhältnis mit dem Bewerber / der Bewerberin
                    eingegangen, werden die Bewerberdaten unter Beachtung einschlägiger Datenschutzvorschriften
                    gespeichert. Wird Ihnen nach Abschluss des Bewerberverfahrens keine Stelle angeboten, so wird Ihr
                    eingereichtes Bewerbungsschreiben samt Unterlagen 6 Monate nach Versand der Absage gelöscht, um
                    etwaigen Ansprüchen und Nachweispflichten nach AGG genügen zu können.
                </li>
            </ol>
            <p><strong>Rechte der betroffenen Person</strong></p>
            <ol>
                <li><strong>Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten<br/></strong>Soweit die
                    Verarbeitung auf Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a), Art. 7 DS-GVO beruht, haben
                    Sie das Recht, die Einwilligung jederzeit zu widerrufen. Die Rechtmäßigkeit der aufgrund der
                    Einwilligung bis zum Widerruf erfolgten Verarbeitung wird dadurch nicht berührt.Soweit wir die
                    Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung gemäß Art. 6 Abs. 1 S. 1 lit.
                    f) DS-GVO stützen, können Sie Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn
                    die Verarbeitung insbesondere nicht zur Erfüllung eines Vertrags mit Ihnen erforderlich ist, was von
                    uns jeweils bei der nachfolgenden Beschreibung der Funktionen dargestellt wird. Bei Ausübung eines
                    solchen Widerspruchs bitten wir um Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten
                    nicht wie von uns durchgeführt verarbeiten sollten. Im Falle Ihres begründeten Widerspruchs prüfen
                    wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen
                    unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung
                    fortführen.Sie können der Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Werbung und
                    Datenanalyse jederzeit widersprechen. Das Widerspruchsrecht können Sie kostenfrei ausüben. Über
                    Ihren Werbewiderspruch können Sie uns unter folgenden Kontaktdaten informieren:Prof. Dr. Bela
                    Gipp<br/>Papendiek 14<br/>D-37073 Göttingen<br/>E-Mail-Adresse: <a
                        href="mailto:gipp@uni-goettingen.de">gipp@uni-goettingen.de</a></li>
                <li><strong>Recht auf Auskunft</strong><br/>Sie haben das Recht, von uns eine Bestätigung darüber zu
                    verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden. Sofern dies der Fall ist,
                    haben Sie ein Recht auf Auskunft über Ihre bei uns gespeicherten persönlichen Daten nach Art. 15
                    DS-GVO. Dies beinhaltet insbesondere die Auskunft über die Verarbeitungszwecke, die Kategorie der
                    personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt
                    wurden oder werden, die geplante Speicherdauer, die Herkunft ihrer Daten, sofern diese nicht direkt
                    bei Ihnen erhoben wurden.
                </li>
                <li><strong>Recht auf Berichtigung</strong><br/>Sie haben ein Recht auf Berichtigung unrichtiger oder
                    auf Vervollständigung richtiger Daten nach Art. 16 DS-GVO.
                </li>
                <li><strong>Recht auf Löschung</strong><br/>Sie haben ein Recht auf Löschung Ihrer bei uns gespeicherten
                    Daten nach Art. 17 DS-GVO, es sei denn gesetzliche oder vertraglichen Aufbewahrungsfristen oder
                    andere gesetzliche Pflichten bzw. Rechte zur weiteren Speicherung stehen dieser entgegen.
                </li>
                <li><strong>Recht auf Einschränkung</strong><br/>Sie haben das Recht, eine Einschränkung bei der
                    Verarbeitung Ihrer personenbezogenen Daten zu verlangen, wenn eine der Voraussetzungen in Art. 18
                    Abs. 1 lit. a) bis d) DS-GVO erfüllt ist:<br/>• Wenn Sie die Richtigkeit der Sie betreffenden
                    personenbezogenen für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit
                    der personenbezogenen Daten zu überprüfen;• die Verarbeitung unrechtmäßig ist und Sie die Löschung
                    der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der
                    personenbezogenen Daten verlangen;• der Verantwortliche die personenbezogenen Daten für die Zwecke
                    der Verarbeitung nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder
                    Verteidigung von Rechtsansprüchen benötigen, oder• wenn Sie Widerspruch gegen die Verarbeitung gemäß
                    Art. 21 Abs. 1 DS-GVO eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe des
                    Verantwortlichen gegenüber Ihren Gründen überwiegen.
                </li>
                <li><strong>Recht auf Datenübertragbarkeit</strong><br/>Sie haben ein Recht auf Datenübertragbarkeit
                    nach Art. 20 DS-GVO, was bedeutet, dass Sie die bei uns über Sie gespeicherten personenbezogenen
                    Daten in einem strukturierten, gängigen und maschinenlesbaren Format erhalten können oder die
                    Übermittlung an einen anderen Verantwortlichen verlangen können.
                </li>
                <li><strong>Recht auf Beschwerde</strong><br/>Sie haben ein Recht auf Beschwerde bei einer
                    Aufsichtsbehörde. In der Regel können Sie sich hierfür an die Aufsichtsbehörde insbesondere in dem
                    Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes
                    wenden.
                </li>
            </ol>
            <p><strong>Datensicherheit</strong></p><p>Um alle personenbezogen Daten, die an uns übermittelt werden, zu
                schützen und um sicherzustellen, dass die Datenschutzvorschriften von uns, aber auch unseren externen
                Dienstleistern eingehalten werden, haben wir geeignete technische und organisatorische
                Sicherheitsmaßnahmen getroffen. Deshalb werden unter anderem alle Daten zwischen Ihrem Browser und
                unserem Server über eine sichere SSL-Verbindung verschlüsselt übertragen.</p><p><strong>Stand:
                08.05.2023</strong></p><p>Quelle: <a className="no-break-out"
                                                     href="https://www.juraforum.de/impressum-generator/">Muster-Datenschutzerklärung
                von JuraForum.de</a></p></div>
    </div>)
}

export default Impressum;